import { Gallery, Share } from "@launerlondon/shop-components";
import { useMetaTags, usePost, usePosts } from "@launerlondon/shop-hooks";
import { Post } from "@launerlondon/shop-posts";
import { routes } from "@launerlondon/shop-router";
import cx from "classnames";
import { Link, Navigate, generatePath, useParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";

const RelatedArticles: React.FC<{ post: Post }> = ({ post }) => {
	const posts = usePosts()
		.filter((p) => !Object.is(post, p))
		.slice(0, 5);
	return (
		<aside className="sticky top-16 max-w-sm p-2">
			<h3 className="font-sans text-lg font-light">Recent articles</h3>
			<div className="divide-y">
				{posts.map((p) => (
					<Link
						key={p.slug}
						className="block"
						to={generatePath(routes.newsView, {
							id: p.id,
							"*": p.slug,
						})}
					>
						<article className="flex gap-4 py-4">
							<div
								className="h-[80px] w-[80px] shrink-0 rounded bg-gray-50 bg-cover"
								style={{
									backgroundImage: `url(${p.images[0]?.thumb.replace(
										".webp",
										".jpeg",
									)})`,
								}}
							/>
							<div>
								<h3 className="text-sm font-light">
									{p.title}
								</h3>
								<time
									className="text-xs"
									dateTime={p.date.toJSON()}
								>
									{p.date.toLocaleDateString()}
								</time>
							</div>
						</article>
					</Link>
				))}
			</div>
		</aside>
	);
};

type Params = { id: string; "*"?: string };
const NewsList: React.FC = () => {
	const { id } = useParams() as Params;
	const post = usePost(id);
	useMetaTags(
		{
			title: post?.title,
			path: generatePath(routes.newsView, {
				id: post?.id || "_",
				"*": post?.slug || "",
			}),
			image: post?.images[0]?.medium,
		},
		[post],
	);

	if (post === undefined) return null;
	if (post === null) return <Navigate to="/not-found" />;

	return (
		<div className="container my-10 flex flex-col items-start justify-between gap-4 md:flex-row">
			<article className="space-y-b">
				<h1 className="font-display max-w-3xl text-3xl font-light">
					{post.title}
				</h1>
				<time
					className="mb-4 block text-sm text-gray-400"
					dateTime={post.date.toJSON()}
				>
					{post.date.toLocaleDateString(undefined, {
						dateStyle: "full",
					})}
				</time>
				{post.text && (
					<div
						className="[&_a]:text-accent-500 max-w-3xl space-y-2 text-sm leading-relaxed"
						dangerouslySetInnerHTML={{ __html: post.text }}
					/>
				)}
				{post.source && (
					<cite className="font-sans text-xs uppercase not-italic text-gray-400">
						{post.source}
					</cite>
				)}
				<div>
					<Gallery
						className="my-10 max-w-3xl"
						images={post.images}
						imageClassName={cx(
							"w-full bg-black object-top",
							post.imageFit === "cover"
								? "object-cover"
								: "object-contain",
						)}
					/>
				</div>
				{post.footer && (
					<div
						className={twJoin(
							"max-w-3xl py-10",
							"space-y-2 text-sm leading-relaxed",
							"[&_a]:text-accent-500",
							"[&>div]:space-y-2",
							"[&_h4]:font-medium",
						)}
						dangerouslySetInnerHTML={{ __html: post.footer }}
					/>
				)}
				<Share
					className="mt-10 font-sans text-sm"
					title="Share this article"
				/>
			</article>
			<RelatedArticles post={post} />
		</div>
	);
};

export default NewsList;
