import { countries, getPaymentCurrencyByCountry } from "@launerlondon/shared";
import type {
	BillingCurrency,
	Country,
	Currency,
	Lang,
	Locale,
	PaymentMethod,
} from "@launerlondon/shop-types";
import type { Action, ActionCreator, Reducer } from "redux";
import currencies from "../../../assets/currencies-shop.json";
import type { RootThunkAction } from "../store";

type LocaleAction =
	| (Action<"SET_LANG"> & { value: Lang })
	| (Action<"SET_CURRENCY"> & { value: Currency })
	| (Action<"SET_PAYMENT_METHOD"> & { value: PaymentMethod })
	| (Action<"SET_COUNTRY"> & { value: Country })
	| (Action<"SET_USER_DATA"> & { currency: Currency; country: Country });

type LocaleReducer = Reducer<Locale, LocaleAction>;
type LocaleActionCreator = ActionCreator<LocaleAction>;
type ThunkActionCreator = () => RootThunkAction<LocaleAction>;

const defaultLang = navigator.language.startsWith("zh")
	? (navigator.language as "zh-CN")
	: "en";
const defaultCountry = (navigator.language.split("-").pop()?.toUpperCase() ||
	"GB") as Country;
const defaultBillingCurrency = getPaymentCurrencyByCountry(defaultCountry);

const initialState: Locale = {
	lang: defaultLang,
	currency: defaultBillingCurrency,
	country: defaultCountry,
	billingCurrency: defaultBillingCurrency,
};

function adjustBillingCurrency(
	country: Country,
	paymentMethod?: PaymentMethod,
): BillingCurrency {
	if (paymentMethod === "alipay") {
		return "CNY";
	}
	if (paymentMethod === "afterpay_clearpay") {
		return "GBP";
	}
	return getPaymentCurrencyByCountry(country);
}

const LocaleReducer: LocaleReducer = (state = initialState, action): Locale => {
	if (action.type === "SET_LANG") {
		if (state.lang === action.value) {
			return state;
		}
		return { ...state, lang: action.value };
	}
	if (action.type === "SET_CURRENCY") {
		if (state.currency === action.value) {
			return state;
		}
		return { ...state, currency: action.value };
	}
	if (action.type === "SET_COUNTRY") {
		if (state.country === action.value) {
			return state;
		}
		return {
			...state,
			country: action.value,
			billingCurrency: adjustBillingCurrency(
				action.value,
				state.paymentMethod,
			),
		};
	}
	if (action.type === "SET_PAYMENT_METHOD") {
		if (state.paymentMethod === action.value) {
			return state;
		}
		return {
			...state,
			billingCurrency: adjustBillingCurrency(state.country, action.value),
			paymentMethod: action.value,
		};
	}
	if (action.type === "SET_USER_DATA") {
		const currency = state.user?.currency;
		const country = state.user?.country;
		if (action.currency === currency && action.country === country) {
			return state;
		}
		return {
			...state,
			currency: action.currency,
			country: action.country,
			billingCurrency: adjustBillingCurrency(
				action.country,
				state.paymentMethod,
			),
			user: { currency: action.currency, country: action.country },
		};
	}
	return state;
};

const setLang: LocaleActionCreator = (value) => ({ type: "SET_LANG", value });
const setCurrency: LocaleActionCreator = (value) => ({
	type: "SET_CURRENCY",
	value,
});
const setPaymentMethod: LocaleActionCreator = (value) => ({
	type: "SET_PAYMENT_METHOD",
	value,
});
const setCountry: LocaleActionCreator = (value) => ({
	type: "SET_COUNTRY",
	value,
});

// prevent ipapi.co being fetched consecutively
let ipFetch: Promise<void> | undefined;

const detectLocale: ThunkActionCreator = () => (dispatch, getState) => {
	const locale = getState().locale as Locale;
	if (locale.user || ipFetch) {
		if (!currencies.includes(locale.currency)) {
			dispatch({
				type: "SET_USER_DATA",
				currency: initialState.currency,
				country: locale.country,
			});
		}
		return;
	}
	ipFetch = fetch("https://ipapi.co/json")
		.then((r) => r.json())
		.then((data) => {
			let currency = data.currency || initialState.currency;
			let country = data.country || initialState.country;
			if (!currencies.includes(currency)) {
				currency = getPaymentCurrencyByCountry(country);
			}
			if (!countries.find((c) => c.value === country)) {
				country = "GB";
			}
			dispatch({ type: "SET_USER_DATA", currency, country });
		})
		.catch(() => {
			const { currency, country } = initialState;
			dispatch({ type: "SET_USER_DATA", currency, country });
		});
};

export default LocaleReducer;
export { setLang, setCurrency, setPaymentMethod, setCountry, detectLocale };
