import { Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

export default function CollectionChristmas() {
	useMetaTags({
		title: "Christmas Shop at Launer | Launer London",
		description:
			"Explore Launer's Christmas Shop for luxury gifts that embody elegance. Find leather goods handmade in Britian. Complimentary worldwide shipping on all bags.",
		image: "/images/collections/christmas-banner.webp",
	});
	return (
		<div className="text-center">
			<Row.WithImage
				image="/images/collections/christmas-banner.webp"
				className="bg-[#6C8C7F] text-sm text-white lg:text-right"
				contentClassName="space-y-10 lg:justify-self-end mb-4 grid place-items-center lg:place-items-end"
			>
				<h1 className="ln-title flex flex-col lg:max-w-[320px]">
					<span className="text-4xl">Present Perfection </span>{" "}
					<span className="text-lg">
						with Launer’s British Heritage
					</span>
				</h1>
			</Row.WithImage>
			<Row.Single>
				<p>
					Let the joy of the festive season begin by visiting our
					Christmas shop, a trove of truly remarkable leather goods
					that celebrate the finest in traditional British
					heritage. We've created dedicated areas to make the task of
					present shopping one of inspiration, there really is
					something to discover for that discerning person in your
					life. 
				</p>
				<p>
					Please check our current Christmas{" "}
					<Link className="underline" to="/delivery-information">
						delivery times here
					</Link>
					.
				</p>
			</Row.Single>
			<Row.WithImage
				reverse
				image="/images/collections/christmas-guide-banner.webp"
				className="bg-[#6C8C7F] text-white"
				contentClassName="space-y-8"
				imageClassName="lg:m-10"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">The Launer Gift Guide</h2>
				</header>
				<p className="my-10 [&_a]:underline">
					Make this festive season magical with our handpicked
					selection of memorable Christmas gift ideas for the loved
					ones in your life. You can now discover our collection of
					beautiful handmade leather items that will make them feel
					extra special and destined to become treasured keepsakes.
				</p>
				<Link
					to="/cc/christmas-gift-ideas"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					View the Guide
				</Link>
			</Row.WithImage>
			<Row.WithImage
				image="/images/collections/christmas-gifts-for-her.webp"
				className="bg-[#C45D5D] text-[#F6F6F6]"
				contentClassName="space-y-8"
				imageClassName="lg:m-10"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Gifts for Her</h2>
				</header>
				<p className="my-10 [&_a]:underline">
					Bring joy with Christmas gifts for her that she will
					treasure forever. Invest in one of our signature{" "}
					<Link to="/c/handbags">handbags</Link>, a{" "}
					<Link to="/c/top-handle-bags">top handle</Link>,{" "}
					<Link to="/c/cross-body-bags">cross body</Link> or{" "}
					<Link to="/c/clutch-bags">clutch</Link> are all stunning
					choices and demonstrate your regard when seeking that
					cherished Christmas gift for your wife or partner.
				</p>
				<Link
					to="/c/gifts-for-her"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					Explore
				</Link>
			</Row.WithImage>
			<div
				className={twJoin(
					"max-lg:hidden",
					"aspect-square max-h-[60vh] w-full",
					"bg-gray-200 bg-cover bg-center lg:bg-fixed",
					"bg-[url('/images/collections/christmas-feature-1.webp')]",
				)}
			/>
			<Row.WithImage
				image="/images/collections/christmas-gifts-for-him.webp"
				className="bg-[#C45D5D] text-[#F6F6F6]"
				contentClassName="space-y-8"
				imageClassName="lg:m-10"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Gifts for Him</h2>
				</header>
				<p className="my-10 [&_a]:underline">
					You have arrived at the finest destination for luxury
					Christmas gifts for him, all crafted by hand by our skilled
					artisans. Discover our distinguished array of wallets, card
					cases and personal items all produced to a meticulous
					standard, considered fine, heritage keepsakes.
				</p>
				<Link
					to="/c/gifts-for-him"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					Explore
				</Link>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/christmas-luxury-stocking-fillers.webp"
				className="bg-[#6C8C7F] text-[#EBE7E7]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Luxury Stocking Fillers</h2>
				</header>
				<p className="my-10">
					Elevate their gift experience this festive season with a
					handmade item from our luxury stocking fillers trove. They
					will cherish and appreciate Launer’s British heritage
					leather goods, created with devotion using traditional
					leather craft skills and featuring our renowned hallmarks.
				</p>
				<Link
					to="/c/luxury-stocking-fillers"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					Explore
				</Link>
			</Row.WithImage>
			<Row.WithImage
				image="/images/collections/christmas-travel-gifts.webp"
				className="bg-[#C45D5D] text-[#F6F6F6]"
				contentClassName="space-y-8"
				imageClassName="lg:m-10"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Gifts for Travel Lovers</h2>
				</header>
				<p className="my-10 [&_a]:underline">
					The destination for travel gifts with the discerning voyager
					in mind. From <Link to="/c/luggage">luggage</Link> to{" "}
					<Link to="/c/wash-bags">wash bags</Link>, passport covers to
					travel <Link to="/c/wallets">wallets</Link>, key fobs and
					beyond, we have a carefully curated offer that conveys
					distinguished luxury and everyday practicality.
				</p>
				<Link
					to="/c/travel-gifts"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					Explore
				</Link>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/christmas-tech-gifts.webp"
				className="bg-[#6C8C7F] text-[#EBE7E7]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Gifts for Tech Lovers</h2>
				</header>
				<p className="my-10">
					A Christmas tech gift is a must and requires traditional
					leather craft carriers to contain and protect them. With
					Launer’s timeless craftwork, we have the perfect tech gifts
					or should we say, receptacles that emit style and reassuring
					functionality.
				</p>
				<Link
					to="/c/tech-gifts"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					Explore
				</Link>
			</Row.WithImage>
			<Row.WithImage
				image="/images/collections/christmas-gifts-under-250.webp"
				className="bg-[#C45D5D] text-[#F6F6F6]"
				contentClassName="space-y-8"
				imageClassName="lg:m-10"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Gifts under £250</h2>
				</header>
				<p className="my-10 [&_a]:underline">
					Consider a gift they will truly covet with Launer’s British
					Heritage leather goods. We have a deluxe selection of gifts
					under £250 which come in a presentation branded gift box and
					can be colour customised, to make them as special as the
					person you are gifting.
				</p>
				<Link
					to="/c/gifts-under-250"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					Explore
				</Link>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/christmas-gift-card.webp"
				className="bg-[#6C8C7F] text-[#EBE7E7]"
				contentClassName="space-y-8"
				imageClassName="lg:m-10"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Launer Gift Card</h2>
				</header>
				<p className="my-10 [&_a]:underline">
					Surprise someone special with a digital gift card from
					Launer. Select the amount in the currency of your choice and
					compose a personal message to the recipient, then allow
					Launer to take care of fulfilling their wishes. It’s so easy
					and enables your giftee to choose the handcrafted item they
					desire at their leisure.
				</p>
				<Link
					to="/p/0001-01"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					Explore
				</Link>
			</Row.WithImage>
		</div>
	);
}
