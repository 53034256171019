import { Localized, useLocalization } from "@launerlondon/l10n";
import { Icon } from "@launerlondon/shop-components";
import { routes } from "@launerlondon/shop-router";
import { MarketingStatus, RootState } from "@launerlondon/shop-types";
import { getSocialUrl } from "@launerlondon/shop-utils";
import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, Link, NavLink, useFetcher } from "react-router-dom";
import { setCustomerId, setMarketingStatus } from "../redux/actions";
import store from "../redux/store";
import CookieBar from "./CookieBar";
import CookieModal from "./CookieModal";

type StyledComponent = { className?: string };
type Link = { id: string; to: string };

const links = {
	about: [
		{
			id: "footer-link-press_office",
			to: routes.press,
		},
		{
			id: "footer-link-wholesale",
			to: routes.wholesale,
		},
		{
			id: "menu-news-press",
			to: generatePath(routes.newsList, { category: "press" }),
		},
		{
			id: "menu-sustainability",
			to: generatePath(routes.sustainability),
		},
	],
	customer: [
		{ id: "footer-link-contact", to: routes.contact },
		{ id: "footer-link-delivery", to: routes.delivery },
		{ id: "footer-link-returns", to: routes.returns },
		{ id: "footer-link-account", to: routes.accountProfile },
		{ id: "menu-swatches", to: routes.swatches },
	],
};
const socials = ["instagram", "pinterest", "facebook"] as const;

const NewsletterForm: React.FC<StyledComponent> = (props) => {
	const { l10n } = useLocalization();
	const form = useRef<HTMLFormElement>(null);
	const { country, lang } = useSelector((s: RootState) => s.locale);
	const { state, data, Form } = useFetcher<{ customerId?: string }>();

	useEffect(() => {
		if (data?.customerId) {
			form.current?.reset();
			store.dispatch(setMarketingStatus(MarketingStatus.Allowed));
			store.dispatch(setCustomerId(data.customerId));
		}
	}, [form, data]);

	return (
		<Form
			className={props.className}
			ref={form}
			action={routes.newsletter}
			method="post"
		>
			<input type="hidden" name="country" value={country} />
			<input type="hidden" name="lang" value={lang} />
			<input type="hidden" name="optIn" value="true" />
			<input
				className="w-full max-w-xs text-center"
				type="email"
				name="email"
				placeholder={l10n.getString("newsletter-email-placeholder")}
				required
			/>
			<button
				className="button w-full max-w-xs tracking-widest"
				disabled={state !== "idle"}
			>
				<Localized id="newsletter-button" />
			</button>
			<Localized
				id="newsletter-footer"
				elems={{
					url: (
						<Link
							className="hover:text-accent"
							to="/terms-conditions-privacy"
						/>
					),
				}}
				children={
					<p className="mx-auto my-5 max-w-[360px] text-gray-400" />
				}
			/>
		</Form>
	);
};

const NavTitle: React.FC<{ id: string }> = ({ id }) => {
	return (
		<h3 className="mb-2 text-sm uppercase tracking-widest">
			<Localized id={id} />
		</h3>
	);
};

const FooterLink: React.FC<Link> = (props) => {
	return (
		<NavLink
			className="hover:text-accent"
			to={props.to}
			rel={props.to.match("account") ? "nofollow" : undefined}
			children={<Localized id={props.id} children={props.id} />}
		/>
	);
};

const NavLinks: React.FC<StyledComponent> = (props) => {
	return (
		<div className={props.className}>
			<div className="order-last flex flex-1 flex-col items-center gap-2 tracking-wide lg:order-none">
				<NavTitle id="footer-section-about" />
				{links.about.map((l) => (
					<FooterLink key={l.id} {...l} />
				))}
			</div>
			<div className="order-first flex flex-1 flex-col items-center gap-2 lg:order-none">
				<NavTitle id="newsletter-title" />
				<NewsletterForm className="w-full" />
				<div className="flex gap-6">
					{socials.map((i) => (
						<a
							key={i}
							href={getSocialUrl(i)}
							target="_blank"
							rel="noreferrer"
							className="hover:text-accent"
							children={<Icon icon={i} className="h-4 w-4" />}
						/>
					))}
				</div>
			</div>
			<div className="flex flex-1 flex-col items-center gap-2 tracking-wide">
				<NavTitle id="footer-section-customer_care" />
				{links.customer.map((l) => (
					<FooterLink key={l.id} {...l} />
				))}
			</div>
		</div>
	);
};

const Copyright: React.FC<StyledComponent> = (props) => {
	const [showCookieModal, setShowCookieModal] = useState(false);
	return (
		<div className={props.className}>
			<Localized
				id="footer-copyright"
				vars={{ year: new Date().getFullYear().toString() }}
			/>{" "}
			<FooterLink to={routes.terms} id="footer-link-terms" /> /{" "}
			<span
				className="hover:text-accent cursor-pointer"
				onClick={() => setShowCookieModal(true)}
			>
				<Localized id="footer-link-cookie-settings" />
			</span>{" "}
			/ <FooterLink to={routes.careers} id="footer-link-careers" />
			/ <FooterLink to={routes.sitemap} id="Sitemap" />
			<CookieBar onSettingsClick={() => setShowCookieModal(true)} />
			<CookieModal
				show={showCookieModal}
				onCancel={() => setShowCookieModal(false)}
			/>
		</div>
	);
};

const Footer: React.FC = () => {
	return (
		<footer className="bg-gray-100 text-center text-sm">
			<NavLinks
				className={cx(
					"container flex flex-col justify-center gap-10 py-10",
					"lg:flex-row",
				)}
			/>
			<Copyright
				className={cx(
					"container mb-4 block",
					"text-center text-[10px] uppercase tracking-widest",
				)}
			/>
		</footer>
	);
};

export default Footer;
