import {
	accountOrderListLoader,
	accountOrderViewAction,
	accountOrderViewLoader,
	appAction,
	appLoader,
	authAction,
	authLoader,
	bookmarksAction,
	checkoutAction,
	checkoutLoader,
	newsletterAction,
	productAction,
	productLoader,
	profileAction,
	profileLoader,
	routes,
	voucherBalanceAction,
} from "@launerlondon/shop-router";
import {
	About,
	Account,
	AccountBookmarks,
	AccountOrderList,
	AccountOrderView,
	AccountProfile,
	Careers,
	Collection,
	CollectionChristmas,
	CollectionChristmasGuide,
	CollectionCleverColourCombinations,
	CollectionHatherton,
	CollectionLeopard,
	CollectionPatentWonder,
	CollectionRangeRoverHouse,
	CollectionRoyalTartan,
	Contact,
	Craftsmanship,
	Customise,
	Delivery,
	Heritage,
	Home,
	Manufacturing,
	NewsList,
	NewsView,
	Press,
	Returns,
	RoyalWarrant,
	Showroom,
	SignIn,
	Sitemap,
	Sustainability,
	Swatches,
	Terms,
	VoucherBalance,
	Wholesale,
} from "@launerlondon/shop-screens";
import { createRoot } from "react-dom/client";
import {
	Navigate,
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromChildren,
} from "react-router-dom";
import AppLayout from "./AppLayout";
import "./index.css";
import {
	Cart,
	LangRedirect,
	NotFound,
	OrderStatus,
	ProductList,
	ProductView,
} from "./screens";

// polyfill for @mladenilic/threesixty.js using webpack's global
window.global ||= window;

const version = String(import.meta.env.VITE_PKG_VERSION);
const environment = String(import.meta.env.MODE);
console.info(`v${version} (${environment})`);

const router = createBrowserRouter(
	createRoutesFromChildren(
		<Route
			id={routes.home}
			path={routes.home}
			loader={appLoader}
			action={appAction}
			element={<AppLayout />}
		>
			<Route index element={<Home />} />
			<Route path="/en/*" element={<LangRedirect lang="en" />} />
			<Route path="/zh/*" element={<LangRedirect lang="zh-CN" />} />
			<Route path={routes.productList} element={<ProductList />} />
			<Route path={routes.searchView} element={<ProductList />} />
			<Route
				path={routes.productView}
				loader={productLoader}
				action={productAction}
				element={<ProductView />}
			/>
			<Route
				path={routes.checkout}
				loader={checkoutLoader}
				action={checkoutAction}
				element={<Cart />}
			/>
			<Route path={routes.orderStatus} element={<OrderStatus />} />
			<Route path={routes.about} element={<About />} />
			<Route path={routes.craftsmanship} element={<Craftsmanship />} />
			<Route path={routes.heritage} element={<Heritage />} />
			<Route path={routes.manufacturing} element={<Manufacturing />} />
			<Route path={routes.royalWarrant} element={<RoyalWarrant />} />
			<Route path={routes.sustainability} element={<Sustainability />} />
			<Route path={routes.customise} element={<Customise />} />
			<Route path={routes.contact} element={<Contact />} />
			<Route path={routes.swatches} element={<Swatches />} />
			<Route path={routes.delivery} element={<Delivery />} />
			<Route path={routes.returns} element={<Returns />} />
			<Route path={routes.wholesale} element={<Wholesale />} />
			<Route path={routes.press} element={<Press />} />
			<Route path={routes.terms} element={<Terms />} />
			<Route path={routes.careers} element={<Careers />} />
			<Route
				path="/cc/the-hatherton-collection"
				element={<CollectionHatherton />}
			/>
			<Route
				path="/cc/range-rover-house"
				element={<CollectionRangeRoverHouse />}
			/>
			<Route
				path="/cc/royal-tartan"
				element={<CollectionRoyalTartan />}
			/>
			<Route
				path="/cc/patent-wonder"
				element={<CollectionPatentWonder />}
			/>
			<Route
				path="/cc/clever-colour-combinations"
				element={<CollectionCleverColourCombinations />}
			/>
			<Route path="/cc/christmas" element={<CollectionChristmas />} />
			<Route
				path="/cc/christmas-gift-ideas"
				element={<CollectionChristmasGuide />}
			/>
			<Route path="/cc/leopard" element={<CollectionLeopard />} />
			<Route path={routes.collectionView} element={<Collection />} />
			<Route path={routes.newsList} element={<NewsList />} />
			<Route path={routes.newsView} element={<NewsView />} />
			<Route path={routes.showroom} element={<Showroom />} />
			<Route
				path={routes.signIn}
				action={authAction}
				loader={() => null}
				element={<SignIn />}
			/>
			<Route
				path={routes.account}
				loader={authLoader}
				element={<Account />}
			>
				<Route
					index
					element={<Navigate to={routes.accountOrderList} replace />}
				/>
				<Route
					path={routes.accountProfile}
					loader={profileLoader}
					action={profileAction}
					element={<AccountProfile />}
				/>
				<Route
					path={routes.accountOrderList}
					loader={accountOrderListLoader}
					element={<AccountOrderList />}
				/>
				<Route
					path={routes.accountOrderView}
					loader={accountOrderViewLoader}
					action={accountOrderViewAction}
					//errorElement={<h4>Order not found.</h4>}
					element={<AccountOrderView />}
				/>
				<Route
					path={routes.accountBookmarks}
					action={bookmarksAction}
					element={<AccountBookmarks />}
				/>
			</Route>
			<Route
				path={routes.voucherBalance}
				action={voucherBalanceAction}
				element={<VoucherBalance />}
			/>
			<Route path={routes.newsletter} action={newsletterAction} />
			<Route path={routes.sitemap} element={<Sitemap />} />
			<Route path={routes.notFound} element={<NotFound />} />
		</Route>,
	),
);

createRoot(document.querySelector(".app")!).render(
	<RouterProvider router={router} />,
);
