import { Localized, LocalizedColors } from "@launerlondon/l10n";
import {
	CartItemSnapOption,
	ProductSnap,
	filterCustomOptions,
	fmtProductName,
	isNewProduct,
} from "@launerlondon/products";
import skus from "@launerlondon/products/src/skus";
import { findSku } from "@launerlondon/shared";
import { useProductListParams } from "@launerlondon/shop-hooks";
import { routes } from "@launerlondon/shop-router";
import { RootState } from "@launerlondon/shop-types";
import cx from "classnames";
import { MouseEventHandler, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, generatePath, useSubmit } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import BookmarkButton from "./BookmarkButton";
import Price from "./Price";
import ProductPreview from "./ProductPreview";

type Props = React.HTMLAttributes<HTMLDivElement> & {
	product: ProductSnap | undefined;
	url?: string | undefined;
	imageContainerClassName?: string;
	hidePrices?: boolean;
	hideNewFlag?: boolean;
	hideBookmarkButton?: boolean;
	options?: CartItemSnapOption[];
	imageQuality?: "thumb" | "medium" | "large";
	skipBaseImage?: boolean;
};

const ProductListItem: React.FC<Props> = (props) => {
	const {
		product,
		url,
		className,
		imageContainerClassName,
		hidePrices,
		options,
		imageQuality,
		hideNewFlag,
		hideBookmarkButton,
		skipBaseImage,
		...attrs
	} = props;
	const { byStyle } = useProductListParams();
	const groupView = byStyle && product?.variants !== 1;
	const currency = useSelector((state: RootState) =>
		state.locale.currency.toLowerCase(),
	);
	const submit = useSubmit();
	const isCustomized =
		props.options && Boolean(filterCustomOptions(props.options).length);

	const onLinkClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
		(e) => {
			if (options) {
				e.preventDefault();
				submit(
					{ options: JSON.stringify(options) },
					{ action: linkTarget, method: "post" },
				);
			}
		},
		[options],
	);

	if (!product) return null;

	const [model, color] = fmtProductName(product.name);
	const price = product.price?.[currency as "gbp"] || 0;
	const linkTarget = useMemo(() => {
		return url || groupView
			? generatePath(routes.searchView, {
					search: String(model?.toLowerCase().replace(/ +/g, "-")),
			  })
			: generatePath(routes.productView, {
					sku: product.sku,
					"*": product.slug,
			  });
	}, [url, product, groupView]);
	const isVoucher = Boolean(findSku(product.sku, skus.vouchers));

	return (
		<article
			{...attrs}
			className={cx("relative overflow-hidden rounded-sm", className)}
		>
			<div>
				{!groupView && !hideBookmarkButton && (
					<BookmarkButton
						product={product}
						options={options}
						className="absolute right-2 top-2 z-10 text-gray-300"
					/>
				)}
				<Link
					to={linkTarget}
					onClick={groupView ? undefined : onLinkClick}
					className={twMerge(
						"relative block w-full overflow-hidden rounded-sm bg-gray-50 pb-[100%]",
						imageContainerClassName,
					)}
				>
					{!hideNewFlag && isNewProduct(product) && (
						<div className="absolute left-1 top-1 z-10 rounded-sm bg-gray-300 p-2 text-[9px] leading-none tracking-wide text-white">
							NEW
						</div>
					)}
					<div
						className={cx(
							"group absolute bottom-0 left-0 right-0 top-0",
							"bg-center bg-no-repeat",
							"bg-contain",
							"transition-opacity duration-300",
							"mix-blend-multiply",
							"transition-transform ease-in-out",
							!product.thumb_model && "hover:scale-125",
						)}
					>
						<ProductPreview
							sku={product.sku}
							options={options}
							className="!bg-[length:75%]"
							showWarning={false}
							imageQuality={imageQuality}
							skipBaseImage={skipBaseImage}
						/>
						{product.thumb_model && (
							<div
								className={cx(
									"absolute inset-0 bg-cover",
									"transition duration-300 ease-in-out",
									"opacity-0 group-hover:opacity-100",
								)}
								style={{
									backgroundImage: `url(${product.thumb_model?.thumb})`,
								}}
							/>
						)}
					</div>
				</Link>
				<div className="relative ">
					<Link
						to={linkTarget}
						onClick={groupView ? undefined : onLinkClick}
						className="block space-y-1 whitespace-nowrap py-4"
					>
						<h3
							className={cx(
								"font-display",
								"text-sm uppercase tracking-wider",
								"text-gray-600",
								"truncate",
							)}
						>
							<Localized
								prefix="product-model"
								children={model?.replace(/^hatherton/i, "")}
							/>
						</h3>
						{!groupView && (
							<h4
								className={cx(
									"text-sm text-gray-500",
									"leading-relaxed tracking-wide",
									"truncate",
								)}
							>
								{isCustomized ? (
									<Localized id="product-customised_label" />
								) : (
									<LocalizedColors colors={color} />
								)}
							</h4>
						)}
						<small className="block text-gray-400">
							{groupView
								? `${product.variants} options available`
								: byStyle
								? ""
								: `Customisation available`}
						</small>
						{!hidePrices && (
							<h4 className="text-xs text-gray-500">
								{isVoucher ? (
									<span>Variable pricing</span>
								) : (
									<>
										{groupView && <span>From </span>}
										<Price value={price} />
									</>
								)}
							</h4>
						)}
					</Link>
				</div>
			</div>
		</article>
	);
};

export default ProductListItem;
