import { Products, Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

export default function CollectionLeopard() {
	useMetaTags({
		title: "Animal Magic",
		description:
			"Leopard print has a made a welcome return this season, making its mark now in a most desirable and plush form. As you would expect from Launer, it’s an elegant affair – leather with an incredible soft touch, surface texture. From a micro evening bag to top handle handbags and a day tote this is leopard with enduring appeal.",
		image: "/images/collections/leopard-banner.webp",
	});
	return (
		<div className="text-center max-xl:text-sm">
			<Row.WithImage
				image="/images/collections/leopard-banner.webp"
				className="bg-black text-sm text-white"
				contentClassName={twJoin(
					"place-self-stretch",
					"grid items-center sm:max-w-full",
				)}
			>
				<h1 className="ln-title flex flex-col gap-2">
					<span className="text-4xl">Animal Magic </span>
				</h1>
			</Row.WithImage>
			<Row.Single className="py-20 text-base">
				<p>
					Leopard print has made a welcome return this season, making
					its mark now in a most desirable and plush form. As you
					would expect from Launer, it’s an elegant affair – leather
					with an incredible soft touch, surface texture. From a micro
					evening bag to top handle handbags and a day tote this is
					leopard with enduring appeal.
				</p>
			</Row.Single>
			<Row.WithImage
				reverse
				image="/images/collections/leopard-traviata.webp"
				className="bg-[#FAF1ED] text-[#343030]"
				contentClassName="space-y-8"
				imageLink="/p/3050-39/traviata-leopard-bark-brown-brown-suede"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Traviata</h2>
					<p className="text-sm">
						Leopard / Bark Brown / Brown Suede
					</p>
				</header>
				<p className="my-10">
					The Traviata is a legend, acclaimed for its structured
					silhouette, exquisite handcrafted detail and signature
					twisted rope emblem. A handbag icon, first introduced in
					1989, achieving International status as the bag of choice
					for Her Majesty Queen Elizabeth II.
				</p>
				<Link
					to="/p/3050-39/traviata-leopard-bark-brown-brown-suede"
					className="inline-block text-sm uppercase tracking-widest lg:!mt-20"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products
				className="container"
				skus={["3050-39", "3121-03", "3119-07", "3118-03"]}
			/>
			<Row.WithImage
				image="/images/collections/leopard-natalia.webp"
				className="bg-[#FAF1ED] text-[#343030]"
				contentClassName="space-y-8"
				imageLink="/p/3121-03/natalia-leopard-bark-brown"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Natalia</h2>
					<p className="text-sm">Leopard / Bark Brown</p>
				</header>
				<p className="my-10">
					An inspiring and refined design that has ensured Natalia's
					coveted status. It's a triumph in understated glamour,
					petite with a modern handwriting, featuring a shorter top
					handle. Named after the International model and
					philanthropist Natalia Vodianova, it radiates poise.
				</p>
				<Link
					to="/p/3121-03/natalia-leopard-bark-brown"
					className="inline-block text-sm uppercase tracking-widest lg:!mt-20"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/leopard-piccolo.webp"
				className="bg-[#FAF1ED] text-[#343030]"
				contentClassName="space-y-8"
				imageLink="/p/3119-07/piccolo-leopard"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Piccolo</h2>
					<p className="text-sm">Leopard</p>
				</header>
				<p className="my-10">
					We view the Piccolo as our micro sensation, our smallest
					handbag to date, based on the signature best selling
					Traviata. Its beauty is in its scaled down proportions and
					exquisite fine detailing. It can be carried or worn cross
					body by attaching the strap and easily accommodates credit
					cards, notes plus a lipstick.
				</p>
				<Link
					to="/p/3119-07/piccolo-leopard"
					className="inline-block text-sm uppercase tracking-widest lg:!mt-20"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Row.WithImage
				image="/images/collections/leopard-sara.webp"
				className="bg-[#FAF1ED] text-[#343030]"
				contentClassName="space-y-8"
				imageLink="/p/3118-03/sara-leopard"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Sara</h2>
					<p className="text-sm">Leopard</p>
				</header>
				<p className="my-10">
					The ultimate tote bag has arrived in the form of Sara.
					Beautifully refined, it's the perfect day bag companion that
					is both spacious and compact. It features double handles for
					carrying or placing over the shoulder. Sara is the essence
					of modern simplicity.
				</p>
				<Link
					to="/p/3118-03/sara-leopard"
					className="inline-block text-sm uppercase tracking-widest lg:!mt-20"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
		</div>
	);
}
