export enum routes {
	home = "/",
	notFound = "*",
	pageView = "/:slug",
	productList = "/c/:slug",
	searchView = "/s/:search",
	productView = "/p/:sku/*",
	checkout = "/co/*",
	orderStatus = "/o",
	newsList = "/news/press",
	newsView = "/n/:id/*",
	collectionView = "/cc/:slug",
	signIn = "/login",
	signOut = "/logout",
	account = "/account",
	accountProfile = "/account/profile",
	accountOrderList = "/account/orders",
	accountOrderView = "/account/orders/:id",
	accountBookmarks = "/account/wishlist",
	about = "/introducing-launer",
	craftsmanship = "/craftsmanship",
	heritage = "/heritage-story",
	manufacturing = "/british-manufacturing",
	royalWarrant = "/royal-warrant",
	sustainability = "/sustainability",
	customise = "/customise-at-launer",
	contact = "/contact-launer",
	swatches = "/colour-portfolio",
	delivery = "/delivery-information",
	returns = "/returns-refunds",
	wholesale = "/wholesale",
	press = "/press-office-contacts",
	terms = "/terms-conditions-privacy",
	careers = "/careers",
	newsletter = "/newsletter",
	voucherBalance = "/gift-card-balance",
	showroom = "/showroom",
	sitemap = "/sitemap",
}

export default routes;
