import {
	Instagram,
	ProductCarousel,
	Row,
	ThreeSixty,
} from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin, twMerge } from "tailwind-merge";

type SubtitleProps = React.PropsWithChildren & { className?: string };

const Subtitle: React.FC<SubtitleProps> = (props) => {
	return (
		<h4
			className={twMerge(
				"ln-subtitle-sans pt-2 text-xs tracking-[0.35em]",
				props.className,
			)}
		>
			{props.children}
		</h4>
	);
};

export default function Home() {
	useMetaTags({
		title: "Launer London | Handmade British Luxury Leather Bags & Goods",
		description:
			"Discover Launer's British handcrafted leather bags, wallets, purses and accessories. Heritage creations crafted from the finest leather since 1941. Shop now.",
		image: "/images/pages/home-banner.webp",
		suffix: false,
	});
	const accentRowBg = "bg-[#E8EEEC]";
	const accentRowFg = "text-[#6B5C5C]";
	return (
		<div>
			<div className="relative">
				<div
					className={twJoin(
						"absolute inset-x-0 top-0 z-10",
						"p-2",
						"text-center font-light leading-relaxed text-white",
						"text-[9px] lg:text-[10px]",
						"uppercase tracking-widest",
						"bg-[#343030] md:bg-[#343030]/70",
					)}
				>
					Complimentary shipping on all handbags and luggage /
					Complimentary lettering for a limited period
				</div>
				<Row.WithImage
					reverse
					image="/images/pages/home-banner.webp"
					className="bg-[#62766E] text-white max-sm:text-sm"
					imageClassName="bg-right"
					contentClassName={twJoin(
						"place-self-stretch sm:max-w-full",
						"text-center",
						"flex flex-col items-center justify-center",
						"gap-4 xl:gap-8",
						"bg-gradient-to-r from-[#62766E] to-[#6C8C7F]",
					)}
				>
					<h1 className="ln-title flex flex-col">
						<span className="text-base">Christmas Shop Open</span>
						<span className="text-4xl">Present</span>
						<span className="text-4xl">Perfection</span>
					</h1>
					<p className="mx-auto max-w-[420px]">
						Let the joy of the festive season begin by visiting our
						Christmas shop, a trove of truly remarkable leather
						goods that celebrate the finest in traditional British
						heritage.
					</p>
					<Link
						to="/cc/christmas"
						className={twJoin(
							"block",
							"mx-auto w-max px-6 py-5",
							"border opacity-80 transition hover:opacity-100",
							"ln-subtitle-sans text-xs tracking-[0.35em]",
							"bg-white text-[#343030]",
						)}
					>
						Shop Christmas
					</Link>
				</Row.WithImage>
			</div>
			<div
				className={twJoin(
					"container mt-1",
					"grid grid-cols-1 gap-1 md:grid-cols-8",
					"text-center uppercase tracking-widest",
				)}
			>
				<Link
					to="/cc/christmas"
					className={twJoin(
						"hidden md:block",
						"md:col-span-4",
						"xl:aspect-[4/4]",
						"bg-cover bg-top",
						"bg-[url('/images/pages/home-carousel-feat.webp')]",
					)}
				/>
				<div
					className={twJoin(
						"md:col-span-4",
						"bg-gradient-to-br from-gray-50 to-gray-200",
						"grid place-items-center gap-10 py-10",
					)}
				>
					<Link to="/c/gifts-for-her" className="block">
						<h3 className="font-display text-xl normal-case tracking-wide">
							Gifts for Her
						</h3>
						<Subtitle className="text-xs">View</Subtitle>
					</Link>
					<ProductCarousel
						products={[
							"3050-37",
							"3050-33",
							"3097-20",
							"3126-07",
							"3067-03",
							"3129-01",
							"3140-03",
							"685-19",
							"254-05",
							"205-05",
						]}
					/>
				</div>
				<Link
					to="/n/2024111101/the-luxe-effect"
					className={twJoin(
						"md:col-span-2",
						"flex flex-col gap-2",
						"p-5 text-sm leading-relaxed",
						accentRowBg,
					)}
				>
					<div className="mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block max-w-[300px] tracking-widest text-[#343030]">
							THE LUXE EFFECT
						</h3>
						<Subtitle className={accentRowFg}>
							JUDI IN GOLD CROC EFFECT
						</Subtitle>
					</div>
					<div
						className={twJoin(
							"relative mx-auto bg-cover bg-right bg-top",
							"w-full flex-1",
							"aspect-square",
						)}
						style={{
							backgroundImage: `url(/images/pages/home-feature-1.webp)`,
						}}
					>
						<span className="absolute bottom-2 right-2 text-xs lowercase text-white">
							@stylensnitch
						</span>
					</div>
				</Link>
				<Link
					to="/n/2024120901/launer-floris"
					className={twJoin(
						"md:col-span-4",
						"flex flex-col gap-2",
						"p-5 text-sm leading-relaxed",
						accentRowBg,
					)}
				>
					<div className=" mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block tracking-widest text-[#343030]">
							LAUNER X FLORIS
						</h3>
						<Subtitle className={accentRowFg}>
							WIN TWO HATHERTON WASH BAGS AND FLORIS LUXURY
							TOILETRIES
						</Subtitle>
					</div>
					<div
						className={twJoin(
							"aspect-[4/3]",
							"bg-cover bg-center",
							"relative",
							"bg-[url('/images/pages/home-feature-2.webp')]",
						)}
					/>
				</Link>
				<Link
					to="/p/3121-03/natalia-leopard-bark-brown"
					className={twJoin(
						"md:col-span-2",
						"relative",
						"flex flex-col gap-2",
						"p-5 text-sm leading-relaxed",
						accentRowBg,
					)}
				>
					<div className="mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block max-w-[300px] tracking-widest text-[#343030]">
							Introducing
						</h3>
						<Subtitle className={accentRowFg}>
							Natalia in Leopard
						</Subtitle>
					</div>
					<div
						className={twJoin(
							"relative mx-auto",
							"bg-[#F8FBFF] bg-cover bg-bottom bg-no-repeat md:bg-contain",
							"w-full flex-1",
							"aspect-square",
							"bg-[url(/images/pages/home-feature-3.webp)]",
						)}
					/>
				</Link>
				<Link
					to="/cc/royal-tartan"
					className={twJoin(
						"md:col-span-4",
						"relative aspect-square",
						"bg-[url('/images/pages/home-3x2.webp')] bg-cover bg-bottom",
					)}
				>
					<div
						className={twJoin(
							"absolute",
							"text-sm text-black lg:text-white",
							"flex items-center justify-center",
							"lg:right-[10%] lg:top-[30%]",
							"max-lg:right-0 max-lg:top-10",
							//"from-black via-black/80 to-black/0 lg:bg-gradient-to-t lg:py-4",
						)}
					>
						<div className="max-w-[100px] space-y-2 p-2 text-center max-lg:bg-white/90">
							<h4 className="font-display">Royal Tartan</h4>
							<Subtitle className="pt-0">View</Subtitle>
						</div>
					</div>
				</Link>
				<Link
					to="/cc/leopard"
					className={twJoin(
						"md:col-span-4",
						"relative aspect-square",
						"bg-[url('/images/pages/home-3x1.webp')] bg-cover bg-center",
					)}
				>
					<div
						className={twJoin(
							"absolute",
							"text-sm md:text-white",
							"flex items-center justify-center",
							"left-0 top-32 xl:left-20",
							"max-w-[180px]",
						)}
					>
						<div className="space-y-2 p-2 max-lg:bg-white/90">
							<h4 className="font-display">Animal Magic</h4>
							<Subtitle className="pt-0 ">VIEW</Subtitle>
						</div>
					</div>
				</Link>
				<Link
					to="/cc/clever-colour-combinations"
					className={twJoin(
						"md:col-span-4",
						"relative aspect-square",
						"bg-[url('/images/pages/home-4x2.webp')] bg-cover bg-center",
					)}
				>
					<div
						className={twJoin(
							"absolute",
							"text-sm text-white",
							"inset-x-0 bottom-4 flex items-center justify-center",
						)}
					>
						<div className="space-y-2 p-2 max-lg:bg-white/90 max-lg:text-black">
							<h4 className="font-display">
								Clever Colour Combinations
							</h4>
							<Subtitle className="pt-0 max-lg:text-[#B18385]">
								View
							</Subtitle>
						</div>
					</div>
				</Link>
				<Link
					to="/cc/the-hatherton-collection"
					className={twJoin(
						"md:col-span-4",
						"relative aspect-square",
						"bg-[url('/images/pages/home-4x1.webp')] bg-cover bg-center",
					)}
				>
					<div
						className={twJoin(
							"absolute",
							"text-sm text-black",
							"flex items-center justify-center",
							"bottom-28 left-0 xl:left-10",
							"max-w-[200px]",
						)}
					>
						<div className="space-y-2 p-2 max-lg:bg-white/90 lg:text-white">
							<h4 className="font-display">
								The Hatherton Collection
							</h4>
							<Subtitle className="pt-0 text-[#A28E3C]">
								Shop now
							</Subtitle>
						</div>
					</div>
				</Link>
			</div>
			<div className="container my-20 text-center uppercase tracking-widest">
				<h3 className="text-lg font-light">
					<Link
						to="/p/3050-37/traviata-platinum-jubilee-tartan"
						className="text-[#BBACA0]"
					>
						Platinum Jubilee Tartan Traviata
					</Link>{" "}
					– through 360º
				</h3>
				<ThreeSixty
					tip="Swipe to rotate"
					className="mx-auto max-w-2xl rounded pb-[100%] md:pb-[700px]"
					image="https://assets.launer.com/images/360/3050-37/{i}_1280x1280.jpeg"
					count={36}
					loop
				/>
			</div>
			<Instagram className="mb-1 mt-14" />
		</div>
	);
}
